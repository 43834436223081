(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name users.list.controller:UsersListCtrl
   *
   * @description
   *
   */
  angular
    .module('users.list')
    .controller('UsersListCtrl', UsersListCtrl);

  function UsersListCtrl(Restangular) {
    var vm = this;

    vm.users = [];
    vm.accessName = accessName;


    Restangular.all('users').getList().then(function (users) {
      vm.users = users;
    });

    function accessName(code) {
      switch (code) {
        case 'admin':
          return 'Admin';
        case 'regular':
          return 'Read only'
      }
      return code;
    }
  }
}());
